.productos-section {
  width: 100%;
  max-width: 1600px;
  height: 100vh;
  margin-bottom: 110px;
  position: relative;
  display: flex;
  flex-direction: row;

  .title {
    width: 50%;
    padding: 30px 0 30px 80px;
    background: #373c41;
    background-color: #074375;
    background-color: #0093e9;
    background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
    position: relative;
    overflow: visible;
    h2 {
      font-weight: 600;
      font-size: 60px;
      line-height: 140%;
      color: #fff;
      padding-top: 20px;
    }
    .banner-white p {
      font-weight: bold;
      font-size: 16px;
      line-height: 140%;
      color: #fff;
      border-bottom: 1px solid #fff;
    }
    .product-img {
      width: 100%;
      max-width: 450px;
      height: auto;
      display: flex;
      justify-content: flex-end;
      padding: 20px 0 10px 0;
      position: absolute;
      right: -3%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .products {
    width: 50%;
    padding: 40px 80px 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    .products-content {
      max-width: 450px;
      overflow: hidden;
      h3 {
        font-weight: 600;
        font-size: 28px;
        line-height: 140%;
      }
      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 180%;
        padding: 20px 0;
      }
    }
  }
  .catalogo {
    display: flex;
    padding: 20px 0;
    h4 {
      font-weight: bold;
      font-size: 15px;
      line-height: 180%;
    }
    ul {
      padding: 10px 0 0 0px;
      width: 90%;
      li {
        font-weight: 500;
        font-size: 14px;
        line-height: 180%;
      }
    }
  }
}

.list-container {
  width: 100%;
  max-width: 1600px;
  padding: 0 80px;
  position: relative;
  margin: 110px 0;
  h2 {
    font-size: 60px;
    padding-top: 20px;
  }

  .list {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

#productos {
  position: absolute;
  top: -70px;
}

strong {
  color: #3c4144;
}

@media (min-width: 900px) and (max-width: 1100px) {
  .productos-section {
    .title {
      padding: 30px 0 30px 50px;
    }
    .products {
      padding: 30px 0 30px 50px;
    }
  }
  .list-container {
    padding: 0 50px;
  }
}

@media (min-width: 299px) and (max-width: 899px) {
  .productos-section {
    height: auto;
    flex-direction: column;
    .title {
      width: 100%;
      padding: 30px 20px 30px 20px;

      .product-img {
        align-items: center;
        justify-content: center;
        position: relative;
      }
    }
    .products {
      width: 100%;
      padding: 60px 20px 30px 20px;
    }
  }
  .productos-section .title .banner-white p {
    font-size: 14px;
  }
  .catalogo {
    flex-direction: column;
  }
  .productos-section .catalogo ul {
    padding-left: 20px;
    padding-top: 0;
  }
  .list-container {
    padding: 0 20px;
  }
}
