.banner p {
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  border-bottom: 1px solid #dbdbdb;
  padding: 1px 0;
}

@media (min-width: 299px) and (max-width: 899px) {
  .banner p {
    font-size: 14px;
  }
}
