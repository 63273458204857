.servicios-section {
  width: 100%;
  max-width: 1600px;
  padding: 0 80px;
  margin: 0 auto;
  margin-bottom: 110px;
  position: relative;

  h2 {
    font-size: 60px;
    padding-top: 20px;
  }
}

#servicios {
  position: absolute;
  top: -80px;
}

.servicios {
  width: 100%;
  margin: 0 auto;
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.servicio-info {
  width: 100%;
  max-width: 1600px;
  padding: 0 80px;
  margin: 110px 0;
  h2 {
    font-size: 38px;
    padding-top: 20px;
  }
}

.servicios-detalles {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 80px;
  display: flex;
  flex-direction: row;

  .imagen {
    width: 50%;
    margin-top: 30px;
    display: flex;
    margin-top: 0;
    object-fit: cover;

    img {
      width: 100%;
      max-width: 500px;
      height: 400px;
    }
  }

  .detalles {
    width: 50%;
    padding: 15px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .detalles-box {
      width: 100%;
      max-width: 500px;
      h3 {
        font-weight: bold;
        font-size: 21px;
        line-height: 180%;
      }
      ul {
        margin: 15px 0 20px 15px;
        li {
          font-weight: 500;
          font-size: 16px;
          line-height: 180%;
        }
      }
    }

    .btn-servicios {
      margin-top: 25px;
    }
  }
}

@media (min-width: 900px) and (max-width: 1100px) {
  .servicios-section {
    padding: 0 50px;
  }

  .servicio-info {
    padding: 0 50px;
  }
}

@media (min-width: 299px) and (max-width: 899px) {
  .servicios-section {
    padding: 0 20px;
  }
  .servicio-info {
    padding: 0 20px;
    .servicios-detalles {
      flex-direction: column;
      margin-top: 20px;
      .imagen {
        width: 100%;
        justify-content: center;

        img {
          height: 300px;
        }
      }
      .detalles {
        width: 100%;
        padding-top: 40px;
      }
    }
  }
}
