.nosotros-section {
  width: 100%;
  max-width: 1600px;
  padding: 0 80px;
  margin-bottom: 110px;
  display: flex;
  flex-direction: row;
  position: relative;

  .nosotros-img {
    width: 45%;
    display: flex;
    justify-content: flex-start;
    object-fit: cover;
    overflow: hidden;

    .img {
      width: 100%;
      max-width: 500px;
      height: 100%;
      max-height: 415px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  #nosotros {
    position: absolute;
    top: -140px;
  }

  .nosotros {
    width: 55%;
    padding: 0 40px;
    overflow: hidden;

    .nosotros-content {
      display: flex;
      flex-direction: column;
      padding: 40px 0;

      h2 {
        font-size: 60px;
        font-weight: 800;
      }
      p {
        font-weight: 500;
        font-size: 16px;
        line-height: 180%;
        padding-top: 30px;
      }
    }
  }
}

@media (min-width: 900px) and (max-width: 1100px) {
  .nosotros-section {
    padding: 0 50px;

    .nosotros {
      padding: 0 20px;
      .nosotros-content {
        padding: 20px 0;
        h2 {
          font-size: 48px;
        }
        p {
          font-size: 14px;
        }
      }
    }
  }
}

@media (min-width: 299px) and (max-width: 899px) {
  .nosotros-section {
    padding: 0 20px;
    flex-direction: column-reverse;

    .nosotros {
      width: 100%;
      padding: 0;
    }

    .nosotros-img {
      width: 100%;
      justify-content: center;
    }
  }
}
