.proyectos-section {
  width: 100%;
  max-width: 1600px;
  height: 650px;
  padding: 0 80px;
  margin-bottom: 110px;
  position: relative;
  background-color: #f5f5f5;

  .proyectos-text {
    width: 50%;
    max-width: 480px;
    height: calc(100% - 325px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    h2 {
      font-weight: 700;
      font-size: 32px;
      line-height: 140%;
    }
    p {
      font-size: 16px;
      line-height: 180%;
      padding-top: 20px;
      font-weight: 500;

      span {
        font-weight: 600;
      }
    }
  }

  .background-img {
    background-image: url("../assets/proyectos.jpg");
    background-size: cover;
    background-position: 50%;
    width: 100%;
    max-width: 50vw;
    position: absolute;
    height: 650px;
    top: 0;
    right: 0;
  }

  .proyectos-data {
    width: 100%;
    height: calc(100% - 325px);
    position: relative;
    padding-top: 50px;

    .data-container {
      display: flex;
      max-width: 80%;

      .data-box {
        width: 33%;
        padding: 70px 0;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px solid #f5f5f5;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);

        p {
          font-weight: 700;
          font-size: 47px;
          line-height: 140%;
          color: #49a4ff;
        }
        span {
          font-weight: 600;
          font-size: 16px;
          line-height: 140%;
          text-align: center;
        }
      }
    }
  }
}

#proyectos {
  position: absolute;
  top: -70px;
}

@media (min-width: 900px) and (max-width: 1100px) {
  .proyectos-section {
    padding: 0 50px;

    .proyectos-text {
      width: 100%;
      max-width: none;
    }

    .background-img {
      max-width: none;
      margin-top: 300px;
      height: calc(100% - 325px);
    }

    .proyectos-data .data-container {
      max-width: none;
      justify-content: space-between;
      padding-top: 50px;

      .data-box {
        max-width: 300px;
      }
    }
  }
}

@media (min-width: 299px) and (max-width: 899px) {
  .proyectos-section {
    padding: 0 20px;
    height: auto;

    .proyectos-text {
      width: 100%;
      padding-top: 30px;
      max-width: none;
    }

    .background-img {
      display: none;
    }

    .proyectos-data .data-container {
      max-width: none;
      flex-direction: column;
      padding-top: 0;

      .data-box {
        width: 100%;
        max-width: none;
        padding: 50px 0;
        margin-bottom: 10px;
      }
    }
  }
}
