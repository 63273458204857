.card {
  width: 32%;
  margin-bottom: 60px;
  height: auto;
  transition: transform 0.3s linear;
  transition: filter 0.15s linear;

  &:hover {
    .consulta {
      transform: translateY(-15%);
    }

    img {
      transform: scale(1.2);
      filter: brightness(90%);
    }
    p {
      opacity: 0;
    }
  }
}

.consulta {
  width: 200px;
  text-align: center;
  margin: 0 auto;
  margin-top: 30px;
  padding: 10px 0;
  font-size: 14px;
  font-weight: 800;
  transition: all 0.2s linear;
  text-transform: uppercase;
  border: 1px solid #3c4144;
  transition: transform 0.15s linear;
}

.card-box {
  width: 100%;
  height: 300px;
  overflow: hidden;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: center;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: relative;
    filter: brightness(60%);
    transition: filter 0.15s linear;
    transition: transform 0.3s linear;
  }

  p {
    position: absolute;
    font-size: 1.3rem;
    font-weight: 900;
    text-shadow: 0 16px 16px rgba(0, 0, 0, 0.4);
    color: #fff;
    text-transform: uppercase;
    transition: opacity 0.1s linear;
  }
}

@media (min-width: 700px) and (max-width: 900px) {
  .card {
    width: 48%;
  }
}

@media (min-width: 299px) and (max-width: 699px) {
  .card {
    width: 100%;
  }
}
