* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  letter-spacing: -0.02em;
  color: #222222;
  color: #3c4144;
}

a {
  text-decoration: none;
}
ul {
  list-style: none;
}

button {
  cursor: pointer;
}

.top-section {
  width: 100%;
  max-width: 1600px;
  height: 100vh;
  margin-bottom: 110px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
  }

  .top-container {
    width: 100%;
    height: 100%;
    padding: 0 80px;
    background: rgba(0, 0, 0, 0.4);
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .call-to-action {
      width: 100%;
      padding-top: 20px;
      max-width: calc(70.33333% - 22.91667px);
      overflow: hidden;

      h1 {
        font-size: 46px;
        text-transform: uppercase;
        line-height: 128%;
        margin-bottom: 15px;
        color: #ffffff;
        text-shadow: 0px 6px 6px rgba(0, 0, 0, 0.4);
      }

      p {
        max-width: 500px;
        font-weight: 600;
        font-size: 18px;
        line-height: 140%;
        color: #ffffff;
        text-shadow: 0px 6px 6px rgba(0, 0, 0, 0.4);
      }
    }
  }
}

.btn-header {
  padding: 15px 40px;
  margin-top: 40px;
  border: none;
  border-radius: 2px;
  background: #0093e9;
  color: #ffffff;
  font-weight: 700;
  transition: all ease 0.5s;

  &:hover {
    background-color: #2776a0;
    color: #fff;
  }
}

.project {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  bottom: 6%;
  padding-right: 80px;
  font-weight: 500;
  overflow: hidden;

  p {
    font-size: 12px;
    color: #ffffff;
    line-height: 160%;
    text-align: end;
  }
}

.footer {
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  line-height: 140%;
  padding: 30px 15px;
  width: 100%;
  background-color: #1a1a1a;
  //background-color: #2776a0;
  color: #fff;
}

@media (min-width: 800px) and (max-width: 1050px) {
  .top-section {
    .top-container {
      padding: 0 50px;
      .call-to-action {
        max-width: calc(75.33333% - 22.91667px);
        h1 {
          font-size: 38px;
        }
        p {
          font-size: 16px;
        }
      }
    }
    .project {
      padding: 0 50px;
    }
  }
}

@media (min-width: 299px) and (max-width: 799px) {
  .top-section {
    .top-container {
      padding: 0 20px;
      .call-to-action {
        max-width: calc(100% - 22.91667px);
        h1 {
          font-size: 23px;
        }
        p {
          font-size: 12px;
        }
      }
    }
    .project {
      padding: 0 20px;
    }
  }
}
