.header {
  width: 100%;
  height: 80px;
  display: flex;
  position: fixed;
  justify-content: space-between;
  align-items: center;
  padding: 5px 80px;
  z-index: 9999;
  background-color: transparent;
  transition: all 0.5s ease;

  .logo-container.logo-green {
    display: none;
  }
}

.header.active {
  background: rgb(255, 255, 255);
  height: 80px;
  .logo-container.logo-white {
    display: none;
  }
  .logo-container.logo-green {
    display: block;
  }
}

.hamburguer {
  display: none;
}

.logo-container {
  width: 200px;
  margin-top: 3px;
}

.menu {
  display: flex;
  flex-direction: row;

  .link {
    font-weight: 600;
    font-size: 14px;
    line-height: 0%;
    margin-left: 15px;
    color: #fff;
    position: relative;

    &::after {
      content: " ";
      display: block;
      width: 0;
      height: 2px;
      background: #fff;
      position: absolute;
      top: 10px;
    }

    &:hover::after {
      width: 100%;
      transition: width 0.3s;
    }
  }
}

.link.active {
  color: #373c41;
  &::after {
    background: #373c41;
  }
}

@media (min-width: 900px) and (max-width: 1100px) {
  .header {
    padding: 5px 50px;
  }
}

@media (min-width: 299px) and (max-width: 899px) {
  .header {
    padding: 5px 20px;
    transition: all 0.5s ease;
  }
  .menu {
    display: flex;
    flex-direction: column;
    transition: all 0.5s ease;
    position: inherit;
    left: 0;
    top: 80px;
    background-color: #1a1a1a;
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-align: center;
    transform: translateX(100%);
    transition: all 0.5s ease;

    .link {
      width: 100%;
      margin: 0;
      padding: 30px 0;
      color: #fff;
      &::after {
        display: none;
      }
    }
  }

  .showMenu {
    transform: translateX(0%);
  }

  .background {
    background-color: #1a1a1a;
  }

  .hamburguer {
    display: inline;
  }
}
