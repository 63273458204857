.contacto-section {
  width: 100%;
  max-width: 1600px;
  padding: 0 80px;
  margin-bottom: 110px;
  position: relative;
  overflow: hidden;

  h2 {
    font-size: 60px;
    padding-top: 15px;
  }
}

#contacto {
  position: absolute;
  top: -80px;
}

.contacto-info {
  padding-top: 30px;
  display: flex;
  flex-direction: row;

  .phone {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 111px;
    overflow: hidden;

    p {
      font-size: 14px;
      line-height: 140%;
      padding: 5px 0;
    }
  }

  .form {
    width: 55%;
    overflow: hidden;

    .input {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 25px;

      label {
        padding-bottom: 8px;
        font-weight: bold;
        font-size: 21px;
        line-height: 140%;
      }

      input {
        border: none;
        border-bottom: 2px solid #49a4ff;
        box-sizing: border-box;
        height: 30px;
        font-size: 16px;
        padding-left: 8px;
      }

      textarea {
        border: none;
        border-bottom: 2px solid #49a4ff;
        box-sizing: border-box;
        height: 80px;
        font-size: 16px;
        padding-left: 8px;
        padding-top: 4px;
      }
    }
  }
}

.btn-position {
  text-align: center;
}

.work-with-us {
  width: 100%;
  background: #ececec;
}
.work-with-us-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 80px 20px;
}

.work-with-us-container h3 {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 2.375rem;
  letter-spacing: 0.02em;
  padding-bottom: 10px;
}

.work-with-us-container p {
  font-size: 21px;
  line-height: 0px;
  letter-spacing: 0.02em;
}
.form-work {
  display: flex;
  flex-direction: column;
  padding-top: 25px;
}

.form-work input {
  width: 100%;
  max-width: 350px;
  margin: 0;
  margin-bottom: 12px;
  color: #5f5b5b;
  border: none;
  border-bottom: 2px solid #1e2d3b;
  padding: 5px 0 5px 5px;
  background: #ececec;
  outline: none;
}

.form-work input[type="file"] {
  display: none;
}

.file-upload {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0;
  font-size: 12px;
  line-height: 0px;
  letter-spacing: 0.02em;
  margin-top: 6px;
  margin-bottom: 20px;
  padding-left: 5px;
}
.file-upload:hover {
  text-decoration: underline;
}
.file-upload img {
  margin: 0;
  padding-right: 3px;
}

.form-work button {
  width: 120px;
  height: 35px;
  border-radius: 4px;
  border: none;
  background-color: #49a4ff;
  color: #fff;
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 0px;

  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 900px) and (max-width: 1100px) {
  .contacto-section {
    padding: 0 50px;
  }
}

@media (min-width: 299px) and (max-width: 899px) {
  .contacto-section {
    padding: 0 20px;
    .contacto-info {
      flex-direction: column;

      .phone {
        width: 100%;
        margin-bottom: 60px;
      }
      .form {
        width: 100%;
      }
    }
  }
}
