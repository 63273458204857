.simple-header {
  width: 100%;
  height: 80px;
  display: flex;
  top: 0;
  left: 0;
  position: fixed;
  justify-content: space-between;
  align-items: center;
  padding: 5px 80px;
  z-index: 9999;
  background-color: #fff;
  transition: all 0.5s ease;
}

.logo-container {
  width: 180px;
  margin-top: 3px;
}

.menu {
  .link-simple {
    font-weight: 600;
    font-size: 14px;
    line-height: 0%;
    margin-left: 15px;
    color: #373c41;
    position: relative;

    &::after {
      content: " ";
      display: block;
      width: 0;
      height: 2px;
      background: #373c41;
      position: absolute;
      top: 10px;
    }

    &:hover::after {
      width: 100%;
      transition: width 0.3s;
    }
  }
}

@media (min-width: 900px) and (max-width: 1100px) {
  .simple-header {
    padding: 5px 50px;
  }
}

@media (min-width: 299px) and (max-width: 899px) {
  .simple-header {
    padding: 5px 20px;
    transition: all 0.5s ease;
  }
  .menu {
    .link-simple {
      width: 100%;
      margin: 0;
      padding: 30px 0;
      color: #fff;
      &::after {
        display: none;
      }
    }
  }
}
